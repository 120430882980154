import { useRtl } from "@hooks/useRtl";
import { Box, Stack, Text } from "@packages/ui";
import CertificateIcon from "@public/home/certificate-01.svg";
import FreeIcon from "@public/home/coins-hand.svg";
import MessageIcon from "@public/home/message-smile-square.svg";
import RouteIcon from "@public/home/route.svg";
import { useTranslations } from "@services/strings";
import Image, { ImageProps } from "next/image";

export const Services = () => {
  const { t } = useTranslations();

  return (
    <>
      <Stack gap={28}>
        <h2 className={"font-semibold text-3xl text-center md:text-4xl"}>
          {t("pages.homepage.services.title")}
        </h2>
        <Text color={"slate500"} className={"text-center"}>
          {t("pages.homepage.services.description")}
        </Text>
      </Stack>
      <Box
        gap={80}
        display={"flex"}
        marginTop={104}
        flexDirection={["column", "row"]}
        alignItems={["center", "start"]}
      >
        <ServiceBox
          imageSrc={MessageIcon}
          heading={t("pages.homepage.services.usp-1.title")}
          description={t("pages.homepage.services.usp-1.description")}
        />
        <ServiceBox
          imageSrc={FreeIcon}
          heading={t("pages.homepage.services.usp-2.title")}
          description={t("pages.homepage.services.usp-2.description")}
        />
        <ServiceBox
          imageSrc={CertificateIcon}
          heading={t("pages.homepage.services.usp-3.title")}
          description={t("pages.homepage.services.usp-3.description")}
        />
        <ServiceBox
          imageSrc={RouteIcon}
          heading={t("pages.homepage.services.usp-4.title")}
          description={t("pages.homepage.services.usp-4.description")}
        />
      </Box>
    </>
  );
};

export const ServiceBox = ({
  imageSrc,
  heading,
  description,
}: {
  imageSrc: ImageProps["src"];
  heading: string;
  description: string;
}) => {
  const rtl = useRtl();

  return (
    <Stack
      gap={24}
      textAlign={["center", rtl ? "right" : "left"]}
      justifyContent={"center"}
      alignItems={["center", "start"]}
      flex={"1 1 100%"}
    >
      <Box
        background={"orange100"}
        borderRadius={8}
        width={64}
        height={64}
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
      >
        <Image src={imageSrc} alt={""} width={40} height={40} />
      </Box>
      <Text fontWeight={"semiBold"} fontSize={20}>
        {heading}
      </Text>
      <Text color={"slate500"}>{description}</Text>
    </Stack>
  );
};
