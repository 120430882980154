import { useTranslations } from "@services/strings";
import Image, { ImageProps } from "next/image";
import * as React from "react";
import { useRef, useEffect } from "react";
import { SwiperContainer, register } from "swiper/element/bundle";
import TestimonialImage1 from "@public/home/testimonials/testimonial_1.jpg";
import TestimonialImage2 from "@public/home/testimonials/testimonial_2.jpg";
import TestimonialImage3 from "@public/home/testimonials/testimonial_3.jpg";
import ArrowIconRight from "@public/icons/arrow-narrow-right.svg";
import ArrowIconLeft from "@public/icons/arrow-narrow-left.svg";
import useDimensions from "react-cool-dimensions";
import { breakpoint } from "@packages/ui";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "swiper-container": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & {
        class?: string;
        navigation?: string;
        pagination?: string;
        loop?: string;
      };
      "swiper-slide": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & {
        class?: string;
        style?: string;
      };
    }
  }
}

register();

const Testimonial = ({
  imageSrc,
  name,
  text,
  title,
}: {
  imageSrc: ImageProps["src"];
  name: string;
  text: string;
  title: string;
}) => {
  return (
    <div className="flex flex-col gap-6">
      <span className="text-slate-500 text-sm text-left rtl:text-right">{`"${text}"`}</span>
      <div className="flex flex-row gap-4 items-center">
        <Image
          src={imageSrc}
          alt={name}
          className="rounded-full overflow-hidden h-16 w-16 flex-shrink-0"
        />
        <div className="flex flex-col gap-2 justify-start">
          <span className="text-slate-500 font-semibold text-left leading-none text-sm">
            {name}
          </span>
          <span className="text-slate-500 text-left leading-none text-sm">
            {title}
          </span>
        </div>
      </div>
    </div>
  );
};

export const Testimonials = () => {
  const { t } = useTranslations();
  const swiperElRef = useRef<SwiperContainer>(null);
  const { observe, currentBreakpoint } = useDimensions({
    breakpoints: { ...breakpoint },
    updateOnBreakpointChange: true,
  });

  // set slide count based on breakpoint
  useEffect(() => {
    if (!swiperElRef.current) return;

    if (currentBreakpoint === "small") {
      swiperElRef.current.slidesPerView = 1;
    } else {
      swiperElRef.current.slidesPerView = 2;
    }
  }, [currentBreakpoint]);

  const handleNext = () => {
    if (!swiperElRef.current) return;
    swiperElRef.current.swiper.slideNext();
  };

  const handlePrev = () => {
    if (!swiperElRef.current) return;
    swiperElRef.current.swiper.slidePrev();
  };

  return (
    <>
      <div
        ref={observe}
        style={{ width: "100vw", height: 0, position: "absolute" }}
      />
      <div className="flex flex-col justify-center items-center gap-6 relative flex-1">
        <div className="flex flex-1 w-full">
          <swiper-container ref={swiperElRef} slides-per-view="1" loop="true">
            <swiper-slide class="p-6 bg-transparent">
              <div className="p-6 shadow-lg bg-white rounded-2xl">
                <Testimonial
                  imageSrc={TestimonialImage1}
                  text={t(
                    "pages.homepage.why-us.Testimonials.testimonial-1.testimonial",
                  )}
                  name={t(
                    "pages.homepage.why-us.Testimonials.testimonial-1.name",
                  )}
                  title={t(
                    "pages.homepage.why-us.Testimonials.testimonial-1.title",
                  )}
                />
              </div>
            </swiper-slide>
            <swiper-slide class="p-6 bg-transparent">
              <div className="p-6 shadow-lg bg-white rounded-2xl">
                <Testimonial
                  imageSrc={TestimonialImage2}
                  text={t(
                    "pages.homepage.why-us.Testimonials.testimonial-2.testimonial",
                  )}
                  name={t(
                    "pages.homepage.why-us.Testimonials.testimonial-2.name",
                  )}
                  title={t(
                    "pages.homepage.why-us.Testimonials.testimonial-2.title",
                  )}
                />
              </div>
            </swiper-slide>
            <swiper-slide class="p-6 bg-transparent">
              <div className="p-6 shadow-lg bg-white rounded-2xl">
                <Testimonial
                  imageSrc={TestimonialImage3}
                  text={t(
                    "pages.homepage.why-us.Testimonials.testimonial-3.testimonial",
                  )}
                  name={t(
                    "pages.homepage.why-us.Testimonials.testimonial-3.name",
                  )}
                  title={t(
                    "pages.homepage.why-us.Testimonials.testimonial-3.title",
                  )}
                />
              </div>
            </swiper-slide>
          </swiper-container>
        </div>
        <div className="flex flex-row gap-4" dir={"ltr"}>
          <button
            className="flex h-12 w-12 rounded-full bg-white justify-center items-center"
            onClick={handlePrev}
            aria-label={t("common.back")}
          >
            <Image src={ArrowIconLeft} height={24} width={24} alt={""} />
          </button>
          <button
            className="flex h-12 w-12 rounded-full bg-white justify-center items-center"
            onClick={handleNext}
            aria-label={t("common.next")}
          >
            <Image src={ArrowIconRight} height={24} width={24} alt={""} />
          </button>
        </div>
      </div>
    </>
  );
};
