import { Box, relative, Text } from "@packages/ui";
import { useTranslations } from "@services/strings";
import clsx from "clsx";
import { DecimalInput, DecimalInputProps } from "./DecimalInput";
import { useRtl } from "@hooks/useRtl";

export const CurrencyInput = (props: DecimalInputProps) => {
  const { currencySymbol } = useTranslations();
  const rtl = useRtl();

  return (
    <div className={clsx([relative])}>
      <DecimalInput {...props} />
      <Box
        position={"absolute"}
        display={"flex"}
        pointerEvents={"none"}
        insetY={0}
        alignItems={"center"}
        paddingX={16}
        style={{ right: rtl ? "unset" : 0, left: rtl ? 0 : "unset" }}
      >
        <Text
          fontSize={12}
          textTransform={"uppercase"}
          fontWeight={"semiBold"}
          letterSpacing={"1px"}
          color={"grey40"}
        >
          {currencySymbol}
        </Text>
      </Box>
    </div>
  );
};
